'use client';

import React, {useRef, useState} from "react";
import FAQ from "./components/FAQ";
import DNSLeakTest from "./components/DNSTest";
import ActionBar from "./components/ActionBar";
import RequestTest from "./components/RequestTest";
import {ArrowRightIcon, Bars3Icon, XMarkIcon} from "@heroicons/react/16/solid";
import Roadmap from "./components/Roadmap";

export default function Main() {
    const [showMenu, setShowMenu] = useState(false);
    const [dnsTestPaused, setDNSTestPaused] = useState(false);
    const requestTestRef = useRef(null);

    const runRequestTest = () => {
        if (!requestTestRef.current) {
            console.log("RequestTest ref not ready")
            return;
        }

        requestTestRef.current.run();
    };

    const toggleDNSTest = () => {
        setDNSTestPaused(prev => !prev);
    };

    const toggleMenu = () => {
        console.log(showMenu);
        setShowMenu(prev => !prev);
    }

    return (
        <div className="container mx-auto max-w-xl">
            <div className="md:py-8 md:flex items-center justify-center">
                <div
                    className="relative overflow-hidden md:bg-white md:rounded-xl md:min-w-96 shadow-lg ring-1 ring-black ring-opacity-5">

                    <div className="px-6">
                        <div className="flex items-center justify-between py-6 border-b border-stone-200">
                            <a href="/">
                                <h1 className="text-base font-semibold leading-7 text-stone-600 md:-mt-2">IPFiltr</h1>
                                <p className="max-w-2xl text-sm text-stone-500">Discover your IP addresses.</p>
                            </a>

                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => toggleMenu()}
                            >
                                {showMenu ?
                                    <>
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                    </>
                                    :
                                    <>
                                        <span className="sr-only">Open menu</span>
                                        <Bars3Icon className="h-6 w-6 p-1" aria-hidden="true"/>
                                    </>
                                }
                            </button>
                        </div>
                    </div>

                    <div>
                        <RequestTest ref={requestTestRef}/>

                        <DNSLeakTest state={dnsTestPaused} toggle={toggleDNSTest}/>

                        <ActionBar runRequestTest={runRequestTest} toggleDNSTest={toggleDNSTest}/>

                        <div className="bg-stone-900 md:rounded-b-lg p-2 pt-4 pb-6 md:p-8">
                            <FAQ/>

                            <div className="border-t border-stone-600 mt-3">
                                <p className="text-sm text-stone-400 mt-6 px-4 md:p-0">Privacy-focused
                                    IP lookup tool! For privacy-conscious individuals who value anonymity and
                                    demand a no-external-trace, zero-log environment.</p>
                            </div>
                        </div>
                    </div>

                    {showMenu ?
                        <div className="absolute top-0 left-0 right-0 bottom-0 bg-stone-50 md:bg-white mt-24 px-6 py-4">
                            <p className="text-sm text-stone-600">
                                Have you ever wondered if your online activity is being monitored? It can be quite
                                unsettling to think that someone might be watching your every move while you browse your
                                favourite websites.
                            </p>

                            {/*

                            <p className="text-sm text-stone-500 md:mt-3 py-4 md:p-0">
                                Download IPFiltr as a systray app for Windows, macOS, and Linux. It's a simple,
                                lightweight and built for your privacy.
                            </p>

                            <div className="divide-y divide-stone-200 pb-4">
                                <div className="py-6">
                                    <a className="text-sm text-stone-600" href="#">
                                        <ArrowRightIcon className="h-3 w-3 text-stone-400 inline mr-2 -mt-1" aria-hidden="true" />
                                        Windows
                                    </a>
                                    <p className="text-xs text-stone-500 mt-1">1.0.0</p>
                                    <p className="text-xs text-stone-500">256: xxxxxxx</p>
                                </div>

                                <div className="py-6">
                                    <a className="text-sm text-stone-600" href="#">
                                        <ArrowRightIcon className="h-3 w-3 text-stone-400 inline mr-2 -mt-1" aria-hidden="true" />
                                        macOS
                                    </a>
                                    <p className="text-xs text-stone-500 mt-1">1.0.0</p>
                                    <p className="text-xs text-stone-500">256: xxxxxxx</p>
                                </div>

                                <div className="py-6">
                                    <a className="text-sm text-stone-600" href="#">
                                        <ArrowRightIcon className="h-3 w-3 text-stone-400 inline mr-2 -mt-1" aria-hidden="true" />
                                        Linux
                                    </a>
                                    <p className="text-xs text-stone-500 mt-1">1.0.0</p>
                                    <p className="text-xs text-stone-500">256: xxxxxxx</p>
                                </div>
                            </div>

                            */}

                            <Roadmap />

                            <p className="text-sm text-stone-600 py-4 border-t border-stone-200">Built by <a
                                className="text-stone-900"
                                target="_blank"
                                href="https://williamo.dev">williamo.dev</a>,
                                this site is part of a wider effort to improve and educate about digital privacy.
                            </p>
                        </div>
                        : <></>
                    }
                </div>
            </div>
        </div>
    )
}
