import React from 'react';

const ActionBar = ({ runRequestTest, toggleDNSTest }) => {
    return (
        <div className="px-4 pb-4">
            <div>
                <div
                    className="hidden bg-stone-50 md:flex flex-wrap items-center px-4 py-2.5 text-xs text-stone-600 rounded-xl">
                    Press{' '}
                    <kbd
                        className="mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2 border-stone-400 text-stone-800">
                        R
                    </kbd>{' '}
                    <span className="inline">to run IP test</span>
                    {', '}
                    <kbd
                        className="mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2 border-stone-400 text-stone-800">
                        P
                    </kbd>{' '}
                    <span className="inline">to toggle DNS test</span>
                </div>

                <div
                    className="md:hidden bg-stone-100 active:bg-stone-200 flex flex-wrap items-center px-4 py-2.5 text-xs text-stone-600 rounded-xl">
                    Press{' '}
                    <span
                        onClick={runRequestTest}
                        className="mx-2 font-semibold text-blue-800">
                        here
                    </span>{' '}
                    <span className="inline">to refresh IP check</span>
                    {', '}
                    <span
                        onClick={toggleDNSTest}
                        className="mx-2 font-semibold text-blue-800">
                        here
                    </span>{' '}
                    <span className="inline">to toggle DNS test</span>
                </div>
            </div>
        </div>
    );
};

export default ActionBar;