'use client';

export default function Notification({ ip }) {
  return (
    <>
      { ip.length > 0 ?
        <>
          <div
            aria-live="assertive"
            className="pointer-events-none fixed w-full inset-0 flex items-end md:px-4 md:py-6 sm:items-start"
          >
            <div className="flex w-full flex-col items-center sm:items-end">
              <div
                className="pointer-events-auto w-full md:max-w-sm overflow-hidden md:rounded-lg bg-stone-50 md:bg-white shadow-lg ring-1 ring-stone-500 ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">{ip}</p>
                      <p className="mt-1 text-sm text-gray-500">Copied to clipboard!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <></>
      }
    </>
  )
}
