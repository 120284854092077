import React from 'react';
import './tailwind.css';
import Main from "./Main";

function App() {
  return (
    <div className="h-full bg-stone-50 mx-auto">
      <Main />
    </div>
  );
}

export default App;
