import React from 'react';
import {ClipboardIcon} from "@heroicons/react/24/outline";

const IPAddressInfo = ({ data, copyToClipboard }) => {
    return (
        <div onClick={() => copyToClipboard(data.IP)} className="py-6">
            <dt className="text-sm font-medium text-stone-600">
                <ClipboardIcon className="h-4 w-4 text-stone-600 inline mr-2 -mt-1" aria-hidden="true" />
                IP{data.Version}
            </dt>
            <dd className="mt-2 text-sm leading-6 text-stone-600">{data.IP}</dd>
            {data.Country.length > 0 ? (
                <p className="text-sm leading-6 text-stone-400 sm:col-span-2 sm:mt-0">
                    <img src={`/flags/${data.ISO}.svg`} alt="" className="h-4 w-4 inline mr-2 -mt-1" aria-hidden="true" />
                    {data.Country} ({data.ISO}), {data.City}
                </p>
            ) : (
                <p className="text-sm leading-6 text-stone-400 sm:col-span-2 sm:mt-0">-</p>
            )}
        </div>
    );
};

export default IPAddressInfo;