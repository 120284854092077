import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import IPAddressInfo from "./IPAddressInfo";
import Notification from "./Notification";

const RequestTest = forwardRef((props, ref) => {
    const defaultInfo = {
        IP: 'Loading...',
        Version: '',
        Country: '',
        City: '',
        ISO: ''
    }
    const [v4Info, setV4Info] = useState(defaultInfo);
    const [v6Info, setV6Info] = useState(defaultInfo);
    const [ipCopied, setIPCopied] = useState('');

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setIPCopied(text);
            setTimeout(() => setIPCopied(''), 3000);
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    useImperativeHandle(ref, () => ({
        run: () => {
            run().then(r => console.log('Request Test Completed'));
        }
    }));

    useEffect(() => {
        run().then(r => console.log('Request Test Completed'));

        const handleKeyDown = (event) => {
            if (event.key === 'r' || event.key === 'R') {
                run().then(r => console.log('Request Test Completed'));
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const run = async () => {
        fetch('https://v4.ipfiltr.com/api/v1/ip')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network v4 response was not ok');
                }

                return response.json();
            })
            .then(response => {
                setResponse('v4', response);
            })
            .catch(() => {
                let info = defaultInfo
                info.IP = 'Not available';

                setV4Info(info);
            });

        fetch('https://v6.ipfiltr.com/api/v1/ip')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network v6 response was not ok');
                }

                return response.json();
            })
            .then(response => {
                setResponse('v6', response);
            })
            .catch(() => {
                let info = defaultInfo
                info.IP = 'Not available';

                setV6Info(info);
            });
    }

    const setResponse = (version, response) => {
        let info = { ...defaultInfo };

        info.Version = version;
        info.IP = response.data.ip || 'Error fetching IP'+version+' address';
        info.Country = response.data.country || 'MaxMind data missing';
        info.City = response.data.city || '';
        info.ISO = response.data.iso_code || '';

        if (version === 'v4') {
            setV4Info(info);
        } else {
            setV6Info(info);
        }
    }

    return (
        <div className="p-2 py-0">
            <Notification ip={ipCopied}/>

            <div className="">
                <dl className="divide-y divide-stone-200 px-4">
                    <IPAddressInfo
                        data={v4Info}
                        copyToClipboard={copyToClipboard}
                    />

                    <IPAddressInfo
                        data={v6Info}
                        copyToClipboard={copyToClipboard}
                    />
                </dl>
            </div>
        </div>
    );
});

export default RequestTest;