import React, { useState, useEffect } from 'react';

function DNSLeakTest({ state, toggle }) {
    const [uniqueId, setUniqueId] = useState('');
    const [domain, setDomain] = useState('');
    const [servers, setServers] = useState([]);
    const [error, setError] = useState('');
    const [requestCount, setRequestCount] = useState(0);
    const [intervalID, setIntervalID] = useState(null);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        if (state) {
            console.log('Paused DNS test');
            clearInterval(intervalID);
            setIntervalID(null)
        } else {
            if (!uniqueId) return;
            if (intervalID) return;

            console.log('Resumed DNS test');
            const interval = setInterval(makeDummyRequest, 3000);
            setIntervalID(interval);
        }
    }, [state, intervalID, uniqueId]);

    const fetchUniqueId = async () => {
        try {
            const response = await fetch('https://ipfiltr.com/api/v1/dns');
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setUniqueId(data.data.servers[0].id);
            setDomain(data.data.servers[0].domain)
        } catch (err) {
            setError('Failed to fetch unique ID');
        }
    };

    const generateDummyString = () => {
        return Math.random().toString(36).substring(2, 15);
    };

    const makeDummyRequest = () => {
        if (!uniqueId) return;

        const dummyString = generateDummyString();
        const url = `https://${uniqueId}.${dummyString}.${domain}`;

        setRequestCount(count => count + 1);

        fetch(url)
            .then(response => console.log(`Response from ${url}:`, response))
            .catch(error => console.log(`Error fetching ${url}:`, error));
    };

    const fetchServers = async () => {
        if (!uniqueId) return;
        try {
            const response = await fetch(`https://ipfiltr.com/api/v1/dns/result?id=${uniqueId}`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();

            setServers(data.data);
            setError('');
        } catch (err) {
            setError('Failed to fetch server list');
        }
    };

    useEffect(() => {
        makeDummyRequest();
        makeDummyRequest();
        makeDummyRequest();
        makeDummyRequest();
        makeDummyRequest();

        if (uniqueId) {
            setTimeout(fetchServers, 1000);
        }

        const interval = setInterval(makeDummyRequest, 2000);
        setIntervalID(interval);

        return () => clearInterval(interval);
    }, [uniqueId]);

    // Fetch unique ID on component mount
    useEffect(() => {
        if (!uniqueId) {
            fetchUniqueId().then(r => console.log('Fetched unique ID'));
        }

        const handleKeyDown = (event) => {
            if (event.key === 'p' || event.key === 'P') {
                toggle();
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [uniqueId]);

    return (
        <div>
            <div className="p-6 pt-0">
                <div className="px-1 pt-0 pb-3 border-t border-stone-200">
                    <p className="text-sm pt-6 font-medium leading-6 text-stone-600">
                        DNS <span className="text-stone-400 text-xs font-normal mr-2">{requestCount} tests performed -</span>
                        {state ?
                            <>
                            <span
                                className="inline-flex items-center text-xs font-normal text-yellow-700">
                                Paused
                            </span>
                            </>
                            :
                            <>
                            <span
                                className="inline-flex items-center text-xs font-medium text-green-700">
                                Running
                            </span>
                            </>
                        }
                    </p>

                    <p className="flex-auto py-0.5 text-xs leading-5 text-stone-500">The IPs listed below show which DNS
                        servers are handling your lookups. If they're not from your chosen DNS provider or VPN, it may
                        indicate a DNS leak, potentially exposing your online activity.</p>
                </div>

                <ul className="space-y-3">
                    {servers.length > 0 ?
                        <>
                            {servers.map((server, index) => (
                                <li key={index} className="relative flex gap-x-1">
                                    <div
                                        className={classNames(
                                            index === servers.length - 1 ? 'h-6' : '-bottom-6',
                                            'absolute left-0 top-0 flex w-6 justify-center'
                                        )}
                                    >
                                        <div className="w-px bg-stone-200"/>
                                    </div>
                                    <div
                                        className="relative flex h-6 w-6 flex-none items-center justify-center bg-stone-50 md:bg-white">
                                        <div
                                            className="h-1.5 w-1.5 rounded-full bg-stone-100 ring-1 ring-stone-300"/>
                                    </div>
                                    <p className="flex-auto py-0.5 text-xs leading-5 text-stone-500">
                                        <span className="text-stone-700">{server.ip}</span> - {server.isp}
                                    </p>
                                </li>
                            ))}
                        </>
                        :
                        <>
                            {error ?
                                <>
                                    <p className="pl-1 flex-auto py-0.5 text-xs leading-5 text-stone-500">Error: {error}</p>
                                </>
                                :
                                <>
                                    <p className="pl-1 flex-auto py-0.5 text-xs leading-5 text-stone-500">Loading...</p>
                                </>
                            }
                        </>}
                </ul>
            </div>
        </div>
    );
}

export default DNSLeakTest;