import {CheckCircleIcon} from '@heroicons/react/20/solid'

const steps = [
    {name: 'Systray app', href: '#', status: 'current'},
    {name: 'Open source', href: '#', status: 'upcoming'},
    {name: 'Timer settings', href: '#', status: 'upcoming'},
    {name: 'Notifications', href: '#', status: 'upcoming'},
]

export default function Roadmap() {
    return (
        <div className="py-6">
            <h3 className="text-sm text-stone-600 pb-3">Roadmap</h3>
            <nav className="" aria-label="Progress">
                <ol className="space-y-4">
                    {steps.map((step) => (
                        <li key={step.name}>
                            {step.status === 'complete' ? (
                                <a href={step.href} className="group">
                  <span className="flex items-start">
                    <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                      <CheckCircleIcon
                          className="h-full w-full text-violet-500"
                          aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                  </span>
                                </a>
                            ) : (
                                <a href={step.href} className="group">
                                    <div className="flex items-start">
                                        <div className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                                             aria-hidden="true">
                                            <div className="h-2 w-2 rounded-full bg-gray-300"/>
                                        </div>
                                        <p className="ml-3 text-sm font-medium text-gray-500">{step.name}</p>
                                    </div>
                                </a>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    )
}
